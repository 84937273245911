<!--
 * @Author: Neko
 * @Date: 2021-04-02 09:15:11
 * @LastEditTime: 2021-04-16 18:12:28
 * @LastEditors: Neko
-->
<template>
  <div class="article__container">
    <div class="article__wrap">
      <div class="header">
        <div class="header__wrap">
          <h2 class="header__title">
            <span class="title">{{policylist.storePolicyJson[1].name}}</span>
          </h2>
        </div>
      </div>

      <div class="body">
        <div class="article"
             v-html="policylist.text" />
      </div>
    </div>

  </div>
</template>

<script>
import CONTACT_US from '@/assets/markdown/CONTACT_US.md'
import PRIVACY_POLICY from '@/assets/markdown/PRIVACY_POLICY.md'
import RETURN_REFUND from '@/assets/markdown/RETURN_REFUND_POLICY.md'
import SHIPPING_POLICY from '@/assets/markdown/SHIPPING_POLICY.md'
import TERMS_OF_CONDITIONS from '@/assets/markdown/TERMS_OF_CONDITIONS.md'
import ABOUT_US from '@/assets/markdown/ABOUT_US.md'
import INTELLECTUAL_PROPERTY_RIGHTS from '@/assets/markdown/INTELLECTUAL_PROPERTY_RIGHTS.md'

import { useRequest } from '@/utils/request.js'


export default {
  setup (_, { root }) {
    const { data: policylist } = useRequest('home/getPolicydetail', {
      data: {
        id: root.$route.query.id
      }
    })

    return {
      policylist
    }
  },
  data () {
    return {
      articleMap: {
        CONTACT_US,
        PRIVACY_POLICY,
        RETURN_REFUND,
        SHIPPING_POLICY,
        TERMS_OF_CONDITIONS,
        ABOUT_US,
        INTELLECTUAL_PROPERTY_RIGHTS
      },
      query:''

    }
  },

  mounted () {
    this.getUrl()
    const { data: policylist } = useRequest('home/getPolicydetail', {
      data: {
        id: this.query
      }
    })
  },

  methods: {
    getUrl () {
      this.query = window.location.search.substring(1).substr(this.query.length-1,1)
      console.log(this.query)
    }
  },

  computed: {
    name () {
      return this.$route.query.name
    },

    article () {
      return this.articleMap[this.name]
    }
  }
}
</script>

<style lang="scss" scoped>
.article__container {
  min-width: 1200px;
  width: 96.9%;
  max-width: 1600px;
  margin: 0 auto;
  box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);

  .article__wrap {
    .header {
      position: relative;
      z-index: 2;
      background: #fff;

      .header__wrap {
        position: relative;
        z-index: 2;
        background: #fff;
      }

      .header__title {
        width: 1200px;
        margin: 0 auto;
        letter-spacing: 2px;
        font-size: 18px;

        .title {
          display: inline-block;
          padding: 32px 0 26px;
          border-bottom: 2px solid #0049ac;
          color: #0d5cab;
        }
      }
    }

    .article {
      width: 900px;
      padding: 20px 0;
      margin: 0 auto;

      ::v-deep h1,
      h2,
      h3,
      h4,
      h5 {
        color: #0d5cab;
      }

      ::v-deep p {
        line-height: 1.5;
        color: #666;
      }
    }
  }
}
</style>
